<template>
  <v-container id="dashboard" fluid tag="section">
    <v-text-field class="mt-4" label="اكتب للبحث" outlined append-icon="mdi-magnify" v-model="search">
    </v-text-field>
  
      <v-data-table :headers="headers" :hide-default-footer="true" :items="items" sort-by="phone" class="elevation-1">
 <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title> {{ $t("Drawer.zyara") }} </v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="890px">

         
            <v-toolbar
              color="primary"
              dark>{{ $t('datatable.message')}}</v-toolbar>
         
            <v-stepper >
              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-card class="mb-4" flat>
                        <v-container>
                          <v-row>
                            <v-col  class=" text-start  " cols="12" sm="12" md="12">
                              
        <p  >{{ $t('datatable.name')}}: {{itemId.name}} </p>
        <p  >رقم الهاتف: {{itemId.phone}} </p>
        <p >{{ $t('datatable.email')}}:<a :href='"mailto:"+itemId.email'> {{itemId.email}} </a>     </p>
        <p >الموضوع: {{itemId.subject}} </p> 
        <p >{{ $t('datatable.message')}}: {{itemId.message}} </p> 
        </v-col>
                          </v-row> 
                        </v-container>

                  </v-card>
                  <v-btn  color="primary"  @click="close">
                    {{$t("close")}}
                  </v-btn>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-dialog>
        </v-toolbar>
      </template>

      <!-- <template v-slot:[`item.actions`]="{ item }">

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <button class="ml-5" @click="getshow(item.id)" v-if="!item.isDeleted" v-bind="attrs"
                    v-on="on">عرض </button>
          </template>
          <span>عرض </span>
        </v-tooltip>
      
      </template> -->
 
         
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">{{ $t("Reloading") }}</v-btn>
      </template>
    </v-data-table>


    <v-pagination class="pagination" total-visible="20" v-model="page" color="#c7000b"
        style="position: relative; top: 20px;" circle="" :length="pageCount">
      </v-pagination>


  </v-container>


</template>

<script>


import axios from "axios";

export default {

  name: "callus",
  data() {
    return {
     
  
      dialog: false,
             page: 1,
        pageCount: 0,
        current_page: 1,
        last_page: 0,
    
      items: [],
      headers: [
        {text: 'id', value: 'id',}
        ,{
        text: this.$t('datatable.name'),
        align: "start",
        value:"name"
      },
      {
        text: 'رقم الهاتف',
        align: "start",
        value:"phone"
      },
      // {
      //   text: this.$t('datatable.message'),
      //   align: "start",
      //   value:"message"
      // },
      // ,
      // {
      //     text: this.$t('Processes'),
      //     value: "actions",
      //     sortable: false
      // }
      ],
      itemId:[],
     
    }
  },
  watch:{
      selected: 'search by sub_cat_id', 
    },
  methods: {
   
    close() {
      this.dialog = false;

    },
    getshow($id){
      try {
        axios.get("/contactus/" + $id, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorizations: "Bearer " + this.$store.state.AdminInfo.token
          }
        }).then(res => {
          this.itemId = res.data;
      this.dialog=true;

        })
            .catch(() => {
            });
      }catch (e) {
        e
      }
    },
    getMoreitems() {
        if (this.current_page <= this.last_page) {
          this.current_page = this.page;
          this.initialize();

        }
      },
    initialize() {
      this.loading = true;
      try {
        axios.get("/zyara?page=" + this.current_page , {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorizations: "Bearer " + this.$store.state.AdminInfo.token
          }
        }).then(res => {
          this.loading = false;
          this.items = res.data.data;
          this.last_page = res.data.last_page;
                     this.pageCount = res.data.last_page;
        })
            .catch(() => {
              this.loading = false;
            });
      }catch (e) {
        e
      }

    },
},
  components: {

  },
  computed: {
    selected: function () {
return this.getMoreitems();
}
},


   created() {
    this.initialize();
    
  }
  }
          
</script>
